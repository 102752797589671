/* Navbar styles */
.navbar {
  background-color: #333;
  color: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 20px;
}

.mobileMenu {
  display: none;
}

.bartop, .barmiddle, .barbottom {
  background-color: #fff;
  height: 3px;
  width: 100%;
  transition: all 0.6s ease;
}

.bartop_open {
  background-color: #fff;
  height: 3px;
  width: 100%;
  transition: all 0.6s ease;
  transform: translate(8px, 15px) rotate(-270deg) ;
}
.barmiddle_open {
  background-color: #fff;
  height: 3px;
  width: 100%;
  transition: all 0.6s ease;
  transform: translate(0px, 5px) rotate(238deg) ;
}
.barbottom_open {
  background-color: #fff;
  height: 3px;
  width: 100%;
  transition: all 0.6s ease;
  transform: translate(-9px, -3px) rotate(-270deg) ;
}

.navbarlinks, .navbarlinks_close {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.navbarlinks li, .navbarlinks_close li {
  margin-right: 20px;
}

.navbarlinks a, .navbarlinks_close a {
  text-decoration: none;
  color: #fff;
}

/* Media query for small screens */
@media (max-width: 768px) {
  .navbar{
    flex-direction: row-reverse;
    align-items: start;
    justify-content:flex-start;
  }
  .mobileMenu{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 20px;
  }
  .navbarlinks_close{
    display: none;
  }
   .navbarlinks {
    width: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding-bottom: 30px;
    position:relative;
    top: 30px;
  }

  .navbarlinks li {
    margin: 10px 0;
  }

}
